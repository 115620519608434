<template>
  <section>
    <validation-observer
      #default="{ handleSubmit, invalid, pristine }"
      ref="refFormObserver"
    >
      <b-card
        header-tag="header"
        border-variant="info"
        header-bg-variant="light-info"
        header-class="py-1"
        class="border my-1"
      >
        <template #header>
          <h6 class="m-0">
            {{ $t('employee.tabInfo') }}
          </h6>
        </template>

        <!-- Form Input -->
        <b-card-body class="p-0 pt-1">
          <!-- SECTION Form -->
          <b-form>
            <!-- <b-row>
              <b-col
                class="my-1"
                cols="12"
              >
                Avatar
                <b-media>
                  <template #aside>
                    <b-avatar
                      ref="previewEl"
                      :src="employeeDataToAdd.avatar"
                      :text="avatarText(`${employeeDataToAdd.firstName} ${employeeDataToAdd.lastName}`)"
                      variant="light-info"
                      size="90px"
                      rounded
                    />
                  </template>
                  <h4 class="mb-1 d-block">
                    {{ `${employeeDataToAdd.firstName} ${employeeDataToAdd.lastName}` }}
                  </h4>
                  Button Update and Remove Avatar
                  <div class="d-flex flex-wrap">
                    <b-button
                      variant="outline-warning"
                      @click="$refs.refInputEl.click()"
                    >
                      <input
                        ref="refInputEl"
                        type="file"
                        class="d-none"
                        @input="inputImageFormData"
                      >
                      <feather-icon
                        icon="EditIcon"
                        class="d-inline mr-50"
                      />
                      <span class="d-sm-inline">Upload</span>
                    </b-button>
                    <b-button
                      variant="outline-danger"
                      class="ml-1"
                      @click="onRemoveAvatar"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        class="d-inline mr-50"
                      />
                      <span class="d-none d-sm-inline">Remove</span>
                    </b-button>
                  </div>
                </b-media>
              </b-col>
            </b-row> -->

            <!-- SECTION Fields -->
            <b-row>
              <!-- ANCHOR Username: required, min:6, userName -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="Username"
                  rules="required|min:4|userName|isUnique:employee,username"
                  mode="eager"
                >
                  <b-form-group
                    label-for="username"
                  >
                    <template #label>
                      {{ $t('employee.userName') }}
                      <span class="text-danger">(*)</span>
                    </template>
                    <b-form-input
                      id="username"
                      v-model="employeeDataToAdd.username"
                      :state="getValidationState(validationContext) === false ? false : null"
                      maxlength="16"
                      :debounce="300"
                      :formatter="upperCaseFormatter"
                      :placeholder="$t('employee.placeholderUsername')"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR Email Address: required, email, max 256 -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="Email"
                  rules="required|email|max:50"
                >
                  <!-- Bỏ unique: |isUnique:employee,email -->
                  <b-form-group
                    label-for="emailAddress"
                  >
                    <template #label>
                      {{ $t('employee.email') }}
                      <span class="text-danger">(*)</span>
                    </template>
                    <b-form-input
                      id="emailAddress"
                      v-model="employeeDataToAdd.emailAddress"
                      :state="getValidationState(validationContext) === false ? false : null"
                      maxlength="50"
                      :debounce="300"
                      lazy-formatter
                      :formatter="trimInput"
                      :placeholder="$t('employee.placeholderEmail')"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR Last Name: required, max 30-->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="Last Name"
                  rules="required|max:30"
                >
                  <b-form-group
                    label-for="lastName"
                  >
                    <template #label>
                      {{ $t('employee.lastName') }}
                      <span class="text-danger">(*)</span>
                    </template>
                    <b-form-input
                      id="lastName"
                      v-model="employeeDataToAdd.lastName"
                      :state="getValidationState(validationContext) === false ? false : null"
                      maxlength="30"
                      lazy-formatter
                      :formatter="trimInput"
                      :placeholder="$t('employee.placeholderLastName')"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR First Name: required, max 30-->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="First Name"
                  rules="required"
                >
                  <b-form-group
                    label-for="firstName"
                  >
                    <template #label>
                      {{ $t('employee.firstName') }}
                      <span class="text-danger">(*)</span>
                    </template>
                    <b-form-input
                      id="firstName"
                      v-model="employeeDataToAdd.firstName"
                      :state="getValidationState(validationContext) === false ? false : null"
                      lazy-formatter
                      :formatter="trimInput"
                      :placeholder="$t('employee.placeholderFirstName')"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR Phone Number -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="Phone"
                  rules="required|phoneNumber"
                >
                  <!-- |isUnique:employee,phonenumber -->
                  <b-form-group
                    label-for="phoneNumber"
                  >
                    <template #label>
                      {{ $t('employee.phoneNumber') }}
                      <span class="text-danger">(*)</span>
                    </template>
                    <b-form-input
                      id="phoneNumber"
                      v-model="employeeDataToAdd.phoneNumber"
                      v-remove-non-numeric-chars.allNumber
                      :state="getValidationState(validationContext) === false ? false : null"
                      lazy-formatter
                      :formatter="trimInput"
                      :debounce="300"
                      :placeholder="$t('employee.placeholderPhoneNumber')"
                    />
                    <!-- maxlength="10" -->

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR Gender -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="Gender"
                  rules="required"
                >
                  <b-form-group
                    label-for="gender"
                    :state="getValidationState(validationContext) === false ? false : null"
                  >
                    <template #label>
                      {{ $t('employee.gender') }}
                    </template>
                    <v-select
                      v-model="employeeDataToAdd.gender"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="genderOptions"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      input-id="gender"
                      :placeholder="$t('employee.placeholderSelectGender')"
                    >
                      <template #option="data">
                        <span>
                          {{ $te(data.label) ? $t(data.label) : data.label }}
                        </span>
                      </template>

                      <template #selected-option="data">
                        <span>
                          {{ $te(data.label) ? $t(data.label) : data.label }}
                        </span>
                      </template>

                      <!-- eslint-disable-next-line vue/no-unused-vars  -->
                      <template #no-options="{ search, searching, loading }">
                        {{ $t('noOptions') }}
                      </template>
                    </v-select>
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext) === false ? false : null"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR Type -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="Employee Type"
                  rules="required"
                >
                  <b-form-group
                    label-for="type"
                    :state="getValidationState(validationContext) === false ? false : null"
                  >
                    <template #label>
                      {{ $t('employee.employeeType') }}
                      <span class="text-danger">(*)</span>
                    </template>
                    <v-select
                      v-model="employeeDataToAdd.type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="isADM ? typeOfEmployeeOptions: typeOfEmployeeOptionsKhacADM"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      input-id="type"
                      :placeholder="$t('employee.placeholderSelectType')"
                    >
                      <template #option="data">
                        <span>
                          {{ $te(data.label) ? $t(data.label) : data.label }}
                        </span>
                      </template>

                      <template #selected-option="data">
                        <span>
                          {{ $te(data.label) ? $t(data.label) : data.label }}
                        </span>
                      </template>

                      <template #no-options>
                        {{ $t('noOptions') }}
                      </template>
                    </v-select>
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext) === false ? false : null"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="Agency"
                  rules="required"
                >
                  <b-form-group
                    label-for="agency"
                    :state="getValidationState(validationContext) === false ? false : null"
                  >
                    <template #label>
                      {{ $t('employee.agencies') }}
                      <span class="text-danger">(*)</span>
                    </template>
                    <v-select
                      v-model="agencyManager"
                      :options="agenciesManagerList"
                      :reduce="(val) => val.id"
                      searchable
                      :filterable="false"
                      input-id="agency"
                      label="agencyName"
                      :placeholder="$t('placeholderSelect')"
                      :disabled="true"
                      @open="openAgenciesManager"
                      @search="searchAgenciesManager"
                    >
                      <!-- @input="(val) => $emit('update:agencyManagerFilter', val)" -->
                      <template #selected-option="data">
                        <div class="d-flex-center justify-content-between">
                          <span
                            class="font-weight-bold d-block text-nowrap"
                          >
                            {{ data.agencyName }}
                          </span>
                          <!-- <span
                            class="font-weight-bold d-block text-nowrap"
                          >
                            {{ data.agencyCode }}
                          </span> -->
                        </div>
                      </template>
                      <template #option="data">
                        <div class="d-flex-center justify-content-between">
                          <span
                            class="font-weight-bold d-block text-nowrap"
                          >
                            {{ data.agencyName }}
                          </span>
                          <span
                            class="font-weight-bold d-block text-nowrap"
                          >
                            {{ data.agencyCode }}
                          </span>
                        </div>
                      </template>
                      <template #spinner="{ loading }">
                        <div
                          v-if="loading"
                          style="border-left-color: rgba(88, 151, 251, 0.71)"
                          class="vs__spinner"
                        />
                      </template>
                      <template #no-options>
                        {{ $t('noOptions') }}
                      </template>
                    </v-select>
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext) === false ? false : null"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR Booker Type -->
              <b-col
                v-if="employeeDataToAdd.type === 'BE'"
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('employee.bookerType')"
                  rules="required"
                >
                  <b-form-group
                    label-for="booker-type"
                    :state="getValidationState(validationContext) === false ? false : null"
                  >
                    <template #label>
                      {{ $t('employee.bookerType') }}
                      <!-- <span class="text-danger">(*)</span> -->
                    </template>
                    <v-select
                      v-model="employeeDataToAdd.bookerType"
                      :options="flightTypeOptions"
                      :reduce="(val) => val.value"
                      :clearable="true"
                      input-id="booker-type"
                      :placeholder="$t('employee.placeholderSelectType')"
                    >
                      <template #option="data">
                        <span>
                          {{ $te(data.label) ? $t(data.label) : data.label }}
                        </span>
                      </template>

                      <template #selected-option="data">
                        <span>
                          {{ $te(data.label) ? $t(data.label) : data.label }}
                        </span>
                      </template>

                      <template #no-options>
                        {{ $t('noOptions') }}
                      </template>
                    </v-select>
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext) === false ? false : null"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR ScheduleCode -->
              <b-col
                v-if="['BE', 'SE'].includes(employeeDataToAdd.type) && isF1"
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="scheduleCode"
                >
                  <b-form-group
                    label-for="scheduleCode"
                  >
                    <template #label>
                      {{ employeeDataToAdd.type === 'BE' ? $t('employee.scheduleCodeBE') : $t('employee.scheduleCodeSE') }}
                    </template>
                    <b-form-input
                      id="scheduleCode"
                      v-model="employeeDataToAdd.scheduleCode"
                      v-remove-non-numeric-chars.allNumber
                      number
                      :disabled="readonly"
                      :state="getValidationState(validationContext) === false ? false : null"
                      lazy-formatter
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <!-- !SECTION  -->
          </b-form>
          <!-- !SECTION -->
        </b-card-body>
      </b-card>
      <!-- SECTION Action Buttons -->
      <div class="d-flex mt-1 justify-content-center">
        <b-button
          variant="secondary"
          @click="backHandle"
        >
          {{ $t('back') }}
        </b-button>

        <b-button
          variant="danger"
          class="mx-1"
          :disabled="pristine"
          @click="clearHandle"
        >
          {{ $t('employee.clear') }}
        </b-button>

        <b-button
          variant="info"
          :disabled="invalid || !canAccess('employee.createEmployee')"
          @click="handleSubmit(nextHandle)"
        >
          {{ $t('next') }}
        </b-button>
      </div>
      <!-- !SECTION -->
    </validation-observer>
  </section>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton, BRow, BCol, BCard,
  BCardBody,
  // BMedia, BAvatar,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { computed, ref } from '@vue/composition-api'
import vSelect from 'vue-select'

import {
  genderOptions, typeOfEmployeeOptions, typeOfEmployeeOptionsKhacADM, flightTypeOptions,
} from '@/constants/selectOptions'
import { getUserData } from '@/api/auth/utils'
import store from '@/store'

import { avatarText } from '@core/utils/filter'
import formValidation from '@core/comp-functions/forms/form-validation'
import { trimInput, upperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

import useEmployeeHandle from '@employee/useEmployeeHandle'

import {
  required, min, email, isUnique, userName,
} from '@validations'

// import { useInputImageFormData } from '@core/comp-functions/forms/form-utils'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardBody,
    // BMedia,
    // BAvatar,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    employeeData: {
      type: Object,
      required: true,
    },
  },
  setup(_, { emit }) {
    const blankEmployeeData = {
      // require
      firstName: '',
      lastName: '',
      type: '',
      username: '',
      emailAddress: null,
      creator: getUserData().employeeData.id,
      phoneNumber: '',
      // optional
      // avatar: '',
      gender: 'MALE', // default
      agencyId: null,
      bookerType: null,
    }

    const employeeDataToAdd = ref(JSON.parse(JSON.stringify(blankEmployeeData)))
    const resetEmployeeData = () => {
      employeeDataToAdd.value = JSON.parse(JSON.stringify(blankEmployeeData))
    }
    const { refFormObserver, getValidationState, resetForm } = formValidation(resetEmployeeData)

    const meData = computed(() => store.getters['userStore/getMeData'])
    const isADM = computed(() => meData.value?.type === 'ADM')
    const isF1 = computed(() => meData.value?.agency?.id === 1000000)

    const {
      addEmployee,
      agenciesManagerList,
      openAgenciesManager,
      searchAgenciesManager,
    } = useEmployeeHandle()

    function nextHandle() {
      if (employeeDataToAdd.value.emailAddress === '') employeeDataToAdd.value.emailAddress = null
      if (employeeDataToAdd.value.scheduleCode === '') employeeDataToAdd.value.scheduleCode = null
      employeeDataToAdd.value.username = employeeDataToAdd.value.username.toLowerCase()
      emit('update:employee-data', employeeDataToAdd.value)
      emit('next-step')
    }

    function backHandle() {
      // resetForm()
      this.$router.go(-1)
    }

    function clearHandle() {
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.clearForm'), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) resetForm()
        })
    }

    // Upload avatar
    // const refInputEl = ref(null)
    // const previewEl = ref(null)

    // const { inputImageFormData } = useInputImageFormData(refInputEl, data => {
    //   employeeDataToAdd.value.avatar = data.url
    // })

    // const onRemoveAvatar = () => {
    //   // eslint-disable-next-line no-param-reassign
    //   employeeDataToAdd.value.avatar = null
    // }

    const agencyManager = getUserData().employeeData.agency
    return {
      employeeDataToAdd,
      addEmployee,
      refFormObserver,
      getValidationState,
      avatarText,

      // select options
      typeOfEmployeeOptions,
      typeOfEmployeeOptionsKhacADM,
      genderOptions,

      // Validations
      required,
      min,
      email,
      isUnique,
      isADM,
      userName,

      nextHandle,
      backHandle,
      clearHandle,

      // Upload avatar
      // refInputEl,
      // previewEl,
      // inputImageFormData,
      // onRemoveAvatar,
      trimInput,
      upperCaseFormatter,
      agenciesManagerList,
      openAgenciesManager,
      searchAgenciesManager,
      agencyManager,

      // for bookerType
      flightTypeOptions,
      isF1,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
